<template>
  <page-container
    is-show-breadcrumbs
    style="padding-bottom: 24px"
    class="custom-el-style"
  >
    <el-main>
      <div>
        <div class="download_text">
          <span>导入标识数据最大支持1000条数据</span>
          <span class="download" @click="handlerOpen"><i class="iconfont icon-xiazai" /> 下载数据模板</span>
        </div>
        <el-upload
          class="upload-demo"
          action
          :http-request="httpRequest"
          :before-remove="beforeRemove"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">点击上传 <i class="iconfont icon-yunduanshangchuan" /> </el-button>
          <div slot="tip" class="el-upload__tip">
            上传文件只能是 xlsx/xls/csv 格式
          </div>
        </el-upload>
      </div>
      <el-button type="primary" class="register" @click="submit">批量注册标识</el-button>
    </el-main>
    <el-dialog title="下载数据模板" width="30%" center :visible.sync="isShowDialog">
      <el-form
        :model="formData"
        ref="ruleForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="标识码分类：" prop="cat_parentid" :label-width="labelWidth">
          <el-select
            class="el-tree-select-input"
            v-model="selectMenu"
            popper-class="select-option"
            ref="select"
            v-bind="selectParams"
            :popper-append-to-body="true"
            v-popover:popover
          />
          <el-popover
            ref="popover"
            popper-class="el-tree-select-popper"
            trigger="click"
            :width="340"
            v-model="isShowPopover"
          >
            <el-scrollbar
              tag="div"
              wrap-class="el-select-dropdown__wrap"
              view-class="el-select-dropdown__list"
            >
              <!-- 树列表 -->
              <el-tree
                ref="tree"
                v-bind="treeParams"
                :data="treeParams.menuList"
                node-key="cat_id"
                @node-click="selectMenuInfo"
              />
            </el-scrollbar>
          </el-popover>
        </el-form-item>
        <el-form-item
          label="请选择数据模板"
          :label-width="labelWidth"
          prop="tpl_id"
        >
          <el-select v-model="formData.tpl_id" @change="getField" placeholder="请选择数据模板">
            <el-option
              v-for="item in dataList"
              :label="item.tpl_name"
              :value="item.tpl_id"
              :key="item.tpl_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="模板详情" v-if="formData.itemsList.length > 0" :label-width="labelWidth">
          <br>
          <div v-for="item in formData.itemsList" :key="item.attr_id"><span :style="{color:item.attr_required == 1 && '#f00'}">{{ item.attr_required == 1 ? "*" : '' }}</span>{{ item.attr_name }}</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="download">下载数据模板</el-button>
      </div>
    </el-dialog>
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import { templateApi, identityApi, identifierApi } from "@/services/api";
import config from "@/config/config";
export default {
  components: {
    PageContainer
  },
  data() {
    return {
      selectMenu: "",
      isEdit: false,
      isShowDialog: false,
      isShowPopover: false,
      treeParams: {
        clickParent: true,
        filterable: false,
        menuList: [],
        props: {
          children: "cat_child",
          label: "cat_name",
          value: "cat_id"
        }
      },
      selectParams: {
        multiple: false,
        clearable: false,
        placeholder: "请选择标识分类"
      },
      formData: {
        itemsList: [],
        tpl_id: "",
        cat_parentid: ""
      },
      dataList: [],
      labelWidth: "120px",
      fileList: [],
      rules: {
        cat_parentid: [
          { required: true, message: "请选择标识码分类", trigger: "change" }
        ],
        tpl_id: [
          { required: true, message: "请选择数据模板", trigger: "change" }
        ]
      }
    };
  },
  created() {
    this.getData();
    this.getCategory();
  },
  watch: {
    isShowDialog(newValue, oldValue) {
      if (!this.isShowDialog) {
        this.selectMenu = "";
        this.$refs["ruleForm"].resetFields();
        this.formData.itemsList = [];
      }
    }
  },
  mounted() {},
  methods: {
    async getData() {
      let { dataList } = await templateApi.list();
      console.log(dataList);
      this.dataList = dataList;
    },
    async getCategory() {
      let { data } = await identifierApi.list();
      this.treeParams.menuList = data.filter(
        item => item.cat_type == 0
      );
      console.log(this.treeParams.menuList);
    },
    async getField(id) {
      console.log(id);
      let { data } = await templateApi.detail({ id });
      console.log(data);
      this.formData.itemsList = data.items;
    },
    selectMenuInfo(row) {
      this.selectMenu = row.cat_name;
      this.formData.cat_parentid = row.cat_id;
      this.isShowPopover = false;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多上传1个文件`);
    },
    handlerOpen() {
      this.isShowDialog = true;
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    async httpRequest(options) {
      this.file = options.file;
      console.log(options);
      if (!(await this.isExcel(this.file))) {
        this.fileList = [];
      }
    },
    // 上传文件类型校验
    isExcel(file) {
      let valid = /\.(xlsx|xls|csv)$/.test(file.name);
      if (!valid) {
        this.$message.error("上传文件只能是 xlsx/xls/csv 格式!");
        return false;
      }
      return true;
    },
    async submit() {
      let { code, message } = await identityApi.batchRegister({
        cat_id: this.formData.cat_parentid,
        tpl_id: this.formData.tpl_id,
        file: this.file
      });
      if (code == 200) {
        this.$message.success("批量添加成功");
        this.$router.push("/manage/identity");
      } else {
        this.$message.error(message);
      }
    },
    download() {
      let mpkey = this.$store.state.mpkey;
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          location.href = `${config.apiHost}/template/export?tpl_id=${this.formData.tpl_id}&cat_id=${this.formData.cat_parentid}&mpkey=${mpkey}`;
        }
      });
      console.log(this.formData.tpl_id);
      console.log(mpkey);
    }
  }
};
</script>
<style lang="less" scoped>
.el-form {
  /deep/.el-select .el-input {
    width: auto !important;
  }
  /deep/ .el-button.el-button--primary {
    min-width: 80px;
  }
}
/deep/ .el-upload-list__item {
  width: 20%;
}
/deep/.el-tree-node__content {
  height: 34px !important;
  line-height: 34px;
}
.download_text {
  margin-bottom: 50px;
}
.download {
  color: #0f40f7;
  font-size: 16px;
  margin-left: 15px;
  cursor: pointer;
}
.primary_btn {
  margin: 0 10px;
}
.register {
  margin-top: 120px;
}
</style>
